import { XF } from "./js/XF";

const elSidebarTopForum = document.getElementById("sidebar-topforum-content");
if (!elSidebarTopForum) throw new Error("Could not find top forums element");

(async () => {
  try {
    const result = await XF.ajax(
      "get",
      "/v1/nodes/top-forums",
      undefined,
      undefined,
      { skipDefault: true }
    );
    await new Promise<void>((r) => {
      XF.setupHtmlInsert(result.html, ($html) => {
        elSidebarTopForum.classList.remove("loader-container");
        elSidebarTopForum.replaceChildren(...$html.toArray());
        r();
      });
    });
  } catch (err) {
    elSidebarTopForum.replaceWith(
      XF.phrase("oops_we_ran_into_some_problems_more_details_console")
    );
    console.error("Failed to load top forums", err);
  } finally {
    elSidebarTopForum.setAttribute("aria-busy", "false");
  }
})();
